<template>
  <v-fade-transition mode="out-in">
    <router-view
      :updating="refreshing"
      :admin="admin"
      @reset="onReset"
    />
  </v-fade-transition>
</template>

<style>
  :root {
    --mobees-black: #26272C;
    --city-blue-50: #ecf0fd;
    --city-blue-100: #DCE4F9;
    --city-blue-400: #698DF2;
    --primary: var(--city-blue-400);
    --success: #45D9CF;
    /* --city-blue-A100: #D3F8F3; */
    --city-blue-A400: #5A81ED;
    --accent: #FF4766;
    --creative-pink-400: #f85f78;
    /* --city-blue-A700: #1F788E;
    --city-blue-300: #63E3D2;
    --city-blue-800: #286367;
    --city-blue-900: #274C53; */
    --white-hover-overlay: rgba(255,255,255,.08);
    --inset-2: inset 0px 3px 1px -2px rgba(0, 0, 0, .2), inset 0px 2px 2px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    --light-border: rgba(0, 0, 0, 0.12);
  }

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  html, body, #app.v-application {
    background: #FBFBFD;
    color: var(--mobees-black);
  }
  @media (min-width: 600px) {
    html, body, #app.v-application {
      /* background: var(--mobees-black); */
    }
  }

  #app .theme--light.v-sheet {
    color: var(--mobees-black);
  }
  #app .outlined {
    border: thin solid var(--light-border);
  }


  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 8px;
  }

  .secondary-type {
    font-family: 'Varela Round', 'Noto Sans', Helvetica, Arial, sans-serif !important;
  }
  
  a {
    text-decoration: none;
  }
  i {
    font-style: normal;
  }

  .scrollable {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .scrollable-x {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .smooth-scroll {
    scroll-behavior: smooth;
  }

  .snap-x {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 8px;
    scroll-margin: 8px;
  }
  .snap-child {
    scroll-snap-align: start;
  }


  #app .v-dialog > .v-card, #app .v-dialog > .v-sheet {
    border-radius: .5rem !important;
  }
  #app .v-bottom-sheet > .v-sheet {
    border-radius: .5rem .5rem 0 0 !important;
  }
  #app .v-dialog.v-dialog--fullscreen .v-card, #app .v-dialog.v-dialog--fullscreen .v-sheet {
    border-radius: 0 !important;
  }
  #app .v-dialog:not(.v-dialog--fullscreen):not(.v-bottom-sheet) {
    max-width: 50vw;
  }
  #app .v-dialog.v-dialog--fullscreen {
    max-height: 100vh;
    top: auto;
  }

  .fill-width {
    width: 100%;
  }

  .text-untruncate {
    overflow: initial !important;
    text-overflow: initial !important;
    white-space: initial !important;
    line-height: 1.4 !important;
  }

  .loading-overlay .loading {
    width: 48px;
    height: 48px;
    opacity: .8;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0px 1000px rgba(255,255,255,.8) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  .theme--dark input:-webkit-autofill,
  .theme--dark input:-webkit-autofill:hover, 
  .theme--dark input:-webkit-autofill:focus,
  .theme--dark textarea:-webkit-autofill,
  .theme--dark textarea:-webkit-autofill:hover,
  .theme--dark textarea:-webkit-autofill:focus,
  .theme--dark select:-webkit-autofill,
  .theme--dark select:-webkit-autofill:hover,
  .theme--dark select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 10000px var(--mobees-black) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  

</style>

<script>

  import services from '@/services.js'
  import { sync } from 'vuex-pathify'

  export default {
    name: 'App',

    metaInfo () {
      return {
        title: 'Mobees',
        titleTemplate: '%s | GO Mobees',
        htmlAttrs: { lang: 'pt-br' },
        meta: [{ charset: 'utf-8' }],
      }
    },

    data () {
      return {
        refreshing: false,
        registration: null,
        updateExists: false,
        report: false,
        temp: {
          user: null,
          redirect: null,
        },
      };
    },

    computed: {
      user: sync('user/data'),
      updated: sync('app/updated'),
      redirect: sync('app/redirect'),
      debug: sync('app/debug'),
      toast: sync('app/toast'),

      admin () {
        const user = this.user;
        const roles = _.has(user, 'roles') ? user.roles : [];
        return _.intersection(roles, [1,5])>0;
      },
    },

    watch: {
      $route (to, from) {
        console.log('App... from', from.path, 'to', to.path);
        if (to.path != '/login'&&from.name!=null) {
          this.redirect = to.path;
          if (_.has(to.params, 'brand')) {
            localStorage.setItem("brand", to.params.brand);
          }
        }
        this.report = (_.has(to.query, 'report')&&to.query.report==1);
        if ('debug' in to.query) this.debug = true;
      },
    },

    methods: {
      ...services,

      notifyUpdate (e) {
        if (this.user.auth.status) this.temp.user = this.user;
        this.temp.redirect = this.redirect;
        localStorage.setItem("temp", JSON.stringify(this.temp));

        if (this.report) {
          this.refreshApp()
        }else{
          this.toggleToast(
            true,
            'Nova versão do app disponível!',
            0,
            {
              toggle: true,
              text: 'Atualizar',
              action: this.refreshApp
            },
          )
        }
      },

      async refreshApp () {
        console.log('refreshApp');
        this.updateExists = false;

        localStorage.setItem("updated", 1);
        localStorage.setItem("user", JSON.stringify(this.user));
        localStorage.removeItem("vuex");

        await this.$workbox.messageSW({ type: "SKIP_WAITING" });
      },

      onReset () {
        console.log('onReset');
        this.toast.toggle = false;
        this.updateExists = false;
        this.refreshing = true;

        localStorage.setItem("updated", 1);
        localStorage.setItem("user", JSON.stringify(this.user));

        setTimeout(() => {
          localStorage.removeItem("vuex");

          window.location.reload();
        },500);
      },

      // getCookie (name) {
      //   const value = `; ${document.cookie}`;
      //   const parts = value.split(`; ${name}=`);
      //   let val = null;

      //   if (parts.length === 2) {
      //     val = parts.pop().split(';').shift();
      //   }

      //   console.log('cookie', val);

      //   return val;
      // }
    },

    created () {
    },

    async mounted () {
      let updated = localStorage.getItem("updated");

      if (updated!=null) {
        localStorage.removeItem("updated");

        const user = localStorage.getItem("user")
        if (user!=null) this.user = Object.assign(this.user, JSON.parse(user));

        let temp = localStorage.getItem("temp")
        if (temp!=null) {
          temp = JSON.parse(temp);
          const redirect = _.has(temp, 'redirect') ? temp.redirect : null;
          if (!_.isNil(redirect)) {
            setTimeout(() => {
              this.$router.replace(redirect).catch((error) => {
                console.warn(error);
              });
            }, 250);
          }
        }

        setTimeout(() => {
          this.toggleToast(
            true,
            'App atualizado!',
            3000,
            false
          )
        }, 500);
      }

      if (this.$workbox) {
        this.$workbox.addEventListener("waiting", () => {
          this.notifyUpdate();
        });
      }

      // window.addEventListener('resize', () => {
      //   // We execute the same script as before
      //   let vh = window.innerHeight * 0.01;
      //   document.documentElement.style.setProperty('--vh', `${vh}px`);
      // });

      this.cancelToasts();
    }
  }
</script>
