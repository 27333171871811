// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  data: {
    segments: {
      selected: 0,
      items: {
        0: {
          title: 'Todos os segmentos',
          percent: 100,
          profile: null
        }
      }
    },
    cache: {
      demographics: {
        key: null,
        source: null,
        timestamp: null,
      },
      daily: {
        key: null,
        source: null,
        timestamp: null,
      },
      geofences: {
        key: null,
        source: null,
        timestamp: null,
      },
      pois: {
        key: null,
        source: null,
        timestamp: null,
      },
      hourly: {
        key: null,
        source: null,
        timestamp: null,
      },
      gallery: {
        key: null,
        source: null,
        timestamp: null,
      },
    },
    filters: {
      campaign: null,
      groups: [],
      period: null,
      metric: 'audience',
      layer: 'cities',
      city: null
    }
  }
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
