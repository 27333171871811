import _ from 'lodash';
import dictionary from '@/dictionary'
const moment = require('moment');
const Papa = require('papaparse');

const utils = {
  getDictionary (term) {
    const d = _.clone(dictionary.default);
    const role = _.last(this.user.roles);
    const dict_role = _.find(dictionary, (terms, keys) => {
      const match = _.indexOf(_.split(keys, '|'), role.toString()) >= 0;
      return match;
    })
    const dict = _.isNil(dict_role) ? d : _.assign({}, d, dict_role);
    return _.isNil(term) ? dict : _.has(dict, term) ? dict[term] : term;
  },


  csvToJson (file, callback) {
    Papa.parse(file, {
      delimiter: file.indexOf('.tsv')>=0 ? '\t' : ',',
      header: true,
      download: true,
      complete: function (results) {
        callback(null, results.data);
      },
      error: function (error) {
        callback(error, null);
      },
    });
  },

  isAiring (active, start, end) {
    return moment().isSameOrAfter(start, 'day') && moment().isSameOrBefore(end, 'day') && active;
  },

  handleError (error, msg, toast) {
    if (!this.isValid(msg)) {
      
      if (this.isOnline) {
        console.log('Network/Server Error', error);
        msg = 'Erro desconhecido. Vamos investigar o que houve! 😊';
      }else{
        msg = 'Sem conexão com a internet.';
        console.log('Offline');
      }
    }
    toast = this.isValid(toast) ? toast : false;
    if (toast) {
      this.toggleToast(...(typeof toast == 'boolean' ? [
        true,
        msg,
        7000,
        false
      ] : toast));
    }
    // if a `message` property exists or not
    const exception = error.message || error;
    this.$gtag.exception(exception);
  },

  trackEvent (category, action, label, value) {
    let event = {};
    if (this.isValid(category)) event['eventCategory'] = category;
    if (this.isValid(action)) event['eventAction'] = action;
    if (this.isValid(label)) event['eventLabel'] = label;
    if (this.isValid(value)) event['eventValue'] = value;

    this.$gtag.event(event);
  },

  getParam (route, key) {
    console.log(route);
    return typeof route.params[key] != 'undefined' ? route.params[key] : null;
  },

  portrait () {
    const w = window.innerWidth;
    const h = window.innerHeight;
    return h > w;
  },

  breakpoint (min, max, log) {
    const sizes = {
      'xs': 600, 
      'sm': 960, 
      'md': 1264, 
      'lg': 1904,
      'xl': 4000
    };
    const w = window.innerWidth;
    // console.log(window.innerWidth > sizes[min], window.innerWidth < sizes[max]);
    let s, e, r;
    if (!_.isNil(min)) {
      s = _.has(sizes, min) ? w > sizes[min] : min;
      if (!_.isNil(max)) {
        e = _.has(sizes, max) ? w < sizes[max] : max;
        r = s && e;
      }else{
        r = s;
      }
    }else{
      r = _.has(sizes, max) ? w < sizes[max] : max;
    }
    if (log) console.log(s, e, r);
    return r;
  },

  logout (cpf, token) {
    axios
      .get('/logout/'+cpf+'/'+token+'/?format=json')
      .then(response => {
        console.log('logout => ',response);
        if(response.data[0].retorno==200||response.data[0].retorno==401){
          this.getout('logout');
        }else{
          this.toggleToast(
            true,
            'Não foi possível realizar o logout.',
            5000,
            false,
          );
        }
      })
      .catch(error => {
        console.log('Network/Server Error', error);
        this.toggleToast(
          true,
          'Erro na solicitação.',
          7000,
          false,
          'error'
        );
      });
  },

  getout (caller) {
    console.log('getout', caller);
    this.user.auth = Object.assign({}, this.user.auth, {
      token: null,
      timestamp: null,
      status: false,
    });
    var temp = {
      user: null,
      redirect: this.redirect,
    }
    localStorage.setItem("temp", JSON.stringify(temp));
    setTimeout(() => {
      localStorage.removeItem("vuex");
      console.log('logged out', this.user.auth.status);
      window.location.href = '/login';
    }, 1500);
  },

  toggleToast (toggle, text, timeout, button, color) {
    if (!toggle) this.toast = Object.assign({}, this.toast, {
      toggle: toggle,
      text: '',
      timeout: 0,
      button: {
        toggle: false,
        texto: 'Ok',
        action: () => {}
      },
      color: ''
    });
    if (typeof button == 'undefined'||typeof button == 'boolean') {
      button = {
        toggle: button===true,
        text: 'Ok',
        action: false
      };
    }
    if(typeof color == 'undefined'){
      color = '';
    }
    this.toast = Object.assign({}, this.toast, {
      toggle: toggle,
      text: text,
      timeout: timeout,
      button: button,
      color: color
    });
  },

  campaignPeriod (campaigns, id, groups, full) {
    campaigns = _.isNil(id) ? null : _.isNil(groups) || _.isEmpty(groups) ? [campaigns[id]] : [campaigns[id], ..._.map(groups, id => campaigns[id])];
    const period = !_.isNil(campaigns) ? _.reduce(campaigns, (period, campaign) => {
      return {
        start: moment(period.start).isBefore(campaign.period.start) ? period.start : campaign.period.start,
        end: moment(period.end).isAfter(campaign.period.end) ? period.end : campaign.period.end,
      }
    }, { start: moment().format('YYYY-MM-DD'), end: moment(0).format('YYYY-MM-DD') }) : {
      start: moment().subtract(7,'d').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    };
    if ((_.isNil(full)||!full)&&moment().isBefore(period.end)) period.end = moment().format('YYYY-MM-DD');
    // console.log('segment period:', period.start, period.end);
    return [period.start, period.end];
  },

  normalize (data) {
    return this.isValid(data) && typeof data == 'string' ? data.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '';
  },

  capitalize (s) {
    if (typeof s !== 'string') return ''
    s = s.toLowerCase();
    return _.map(s.split(' '), p => p.length > 2 ? p.charAt(0).toUpperCase() + p.slice(1) : p).join(' ');
  },

  rawData (data) {
    return this.isValid(data) ? data.replace(/[^\w]/gi, '') : '';
  },

  formatCPF (cpf) {
    cpf = this.rawData(cpf);
    return cpf.substr(0,3)+'.'+cpf.substr(3,3)+'.'+cpf.substr(6,3)+'-'+cpf.substr(9,2);
  },

  formatBuzzerCode (code) {
    code = this.rawData(code);
    return code.substr(0,4)+' '+code.substr(4,4)+' '+code.substr(8,4)+' '+code.substr(12,4);
  },

  isValid (data) {
    return typeof data !== 'undefined' ? data !== null : false;
  },

  cancelToasts () {
    if (this.toast.toggle) {
      this.toast = Object.assign({}, this.toast, {
        toggle: false
      });
    }
  },

  interpolateHsl (lowHsl, highHsl, fraction) {
    var color = [];
    for (var i = 0; i < 3; i++) {
      // Calculate color based on the fraction.
      color[i] = (highHsl[i] - lowHsl[i]) * fraction + lowHsl[i];
    }

    return 'hsl(' + color[0] + ',' + color[1] + '%,' + color[2] + '%)';
  },

  random (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}

const icons = {
  cpm: "M8.284 11.8622C6.376 11.3911 4 10.8044 4 8.33333C4 6.74222 5.323 5.39111 7.15 4.93778V3H9.85V4.93778C11.677 5.39111 13 6.74222 13 8.33333H11.2C11.2 7.37333 9.967 6.55556 8.5 6.55556C7.033 6.55556 5.8 7.37333 5.8 8.33333C5.8 9.31111 6.736 9.66667 8.716 10.1378C9.73641 10.3897 10.8907 10.6747 11.7438 11.2634L10.4496 12.5552C9.96809 12.2946 9.25039 12.0922 8.284 11.8622ZM4 13.6667H5.8C5.8 14.4325 6.5846 15.1078 7.6467 15.3496L6.25382 16.7509C4.90598 16.1357 4 14.9833 4 13.6667ZM15.6667 15L13.3333 18L11.6667 16L9.33333 19H18.6667L15.6667 15ZM20 10.3333V19.6667C20 20.0203 19.8595 20.3594 19.6095 20.6095C19.3594 20.8595 19.0203 21 18.6667 21H9.33333C8.97971 21 8.64057 20.8595 8.39052 20.6095C8.14048 20.3594 8 20.0203 8 19.6667V17L16 9H18.6667C19.4 9 20 9.59333 20 10.3333Z",
  gallery: "M8.284 11.8622C6.376 11.3911 4 10.8044 4 8.33333C4 6.74222 5.323 5.39111 7.15 4.93778V3H9.85V4.93778C11.677 5.39111 13 6.74222 13 8.33333H11.2C11.2 7.37333 9.967 6.55556 8.5 6.55556C7.033 6.55556 5.8 7.37333 5.8 8.33333C5.8 9.31111 6.736 9.66667 8.716 10.1378C9.73641 10.3897 10.8907 10.6747 11.7438 11.2634L10.4496 12.5552C9.96809 12.2946 9.25039 12.0922 8.284 11.8622ZM4 13.6667H5.8C5.8 14.4325 6.5846 15.1078 7.6467 15.3496L6.25382 16.7509C4.90598 16.1357 4 14.9833 4 13.6667ZM15.6667 15L13.3333 18L11.6667 16L9.33333 19H18.6667L15.6667 15ZM20 10.3333V19.6667C20 20.0203 19.8595 20.3594 19.6095 20.6095C19.3594 20.8595 19.0203 21 18.6667 21H9.33333C8.97971 21 8.64057 20.8595 8.39052 20.6095C8.14048 20.3594 8 20.0203 8 19.6667V17L16 9H18.6667C19.4 9 20 9.59333 20 10.3333Z"
};

export { 
  utils as default,
  icons
}
