// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  views: {
    forgotPassword: {
      toggle: false,
      feedback: {
        toggle: false,
        timeout: 0,
        text: '',
        button: true
      } 
    },
    help: {
      toggle: false,
      context: null,
    },
    tos: {
      toggle: false,
      accepted: false,
    },
  }
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
