// Imports
import Vue from 'vue'
import Router from 'vue-router'
import vuetify from '@/plugins/vuetify'
import VueGtag from "vue-gtag";

Vue.use(Router)

function layout (path, folder, name, children) {
  return {
    path,
    component: () => import(`@/layouts/${folder}/Layout`),
    children,
  }
}

function redirect (path, redirect) {
  return { path: path, redirect }
}

function route (path, folder, name, children) {
  const route = {
    path,
    name,
    component: () => import(`@/views/${folder}/Index.vue`),
  }
  if (typeof children != 'undefined') route.children = children;
  return route;
}

function subroute (path, folder, name, file) {
  return {
    path,
    name,
    component: () => import(`@/views/${folder}/${file}.vue`),
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = false;
    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition!=null) {
      scrollTo = savedPosition.y
    }

    return scrollTo ? vuetify.framework.goTo(scrollTo) : false;
  },
  routes: [
    subroute('/maintenance', 'help', 'Maintenance', 'Maintenance'),

    layout('/login', 'login', 'LoginLayout', [
      route('access/:username/:token', 'login', 'Access'),
      route(':username?/:token?', 'login', 'Login'),
    ]),

    layout('/enxamedeamor', 'login', 'Media Creator', [
      route('/', '_enxamedeamor', 'Enxame de Amor'),
    ]),
    
    layout('/:brand?', 'home', 'Home', [
      route('campaigns/:campaign/:ad', 'campaigns', 'Anúncio'),
      route('campaigns/:campaign', 'campaigns', 'Campanha'),
      route('campaigns', 'campaigns', 'Campanhas'),
      
      route('dashboard', 'home', 'Dashboard', [
        subroute('timelapse/:campaign/:period/:groups?', 'home', 'Timelapse', 'Timelapse'),
        subroute('map/:campaign/:period/:groups?', 'home', 'Mapa', 'Map'),
        subroute('demographics/:campaign/:period/:groups?', 'home', 'Perfil Demográfico', 'Demographics'),
        subroute('audience/:campaign/:period/:groups?', 'home', 'Impactos', 'Audience'),
        subroute('impressions/:campaign/:period/:groups?', 'home', 'Impressões', 'Impressions'),
        subroute('budget/:campaign/:period/:groups?', 'home', 'Investimento', 'Budget'),
        subroute('cpm/:campaign/:period/:groups?', 'home', 'CPM', 'Cpm'),
        subroute('hours/:campaign/:period/:groups?', 'home', 'Horas no ar', 'Hours'),
        subroute('gallery/:campaign/:period/:groups?', 'home', 'Galeria', 'Gallery'),
        subroute(':campaign?/:period?/:groups?', 'home', 'Overview', 'Overview'),
      ]),  
      
      route('insights', 'insights', 'Insights'),
    ]),
    // redirect('*', { name: 'Dashboard' }),
  ],
})

router.beforeEach((to, from, next) => {
  const inMaintenance = process.env.VUE_APP_MNT==='true';
  if (inMaintenance&&to.name!='Maintenance') {
    next({ name: 'Maintenance' })
    return;
  }else if (!inMaintenance&&to.name=='Maintenance') {
    next({ path: '/' })
    return;
  }
  const user = JSON.parse(localStorage.getItem("user"));
  const isAuthenticated = user!=null && user.username!=null && user.auth.token!=null;
  if (to.name!='Maintenance' && to.path.indexOf('/login')<0 && !isAuthenticated) next({ name: 'Login' })
  else next()
})

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics

const toggle = process.env.NODE_ENV === 'production';

Vue.use(VueGtag, {
  enabled: toggle,
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  },
  pageTrackerTemplate (route) {
    console.log(route.meta.title||route.name)
    return {
      page: route.path,
      title: route.meta.title + ' | GO Mobees',
      location: window.location.href
    }
  },
  // debug: {
  //   // enabled: !toggle,
  //   sendHitTask: toggle,
  // },
}, router);

export default router
