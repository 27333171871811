exports.install = function (Vue, options) {
  if (!options) options = {}
  if (!options.duration) options.duration = 2000

  Vue.directive('long-press', {
    bind: function (el, binding, vnode) {
      var self = this

      this._timeout = null
      this._hasMoved = false
      this.duration = binding.value.duration;
      this.controller = {
        toggle: false,
        timer: null,
        step: 0,
        progress: 0,
        timestamp: null
      }

      this._onmousedown = function (e) {
        self.start()
      }

      this._ontouchstart = function (e) {
        self.start()

        // self._timeout = setTimeout(function () {
        //   console.log('press-start timeout');
        //   if (!self._hasMoved) {
        //     console.log('press-start timeout hasMoved');
        //     binding.value.call(context, e)
        //     self._hasMoved = false
        //   }
        // }, options.duration)
      }

      this._onmouseup = function () {
        self.cancel()
        // clearTimeout(self._timeout)

      }
      
      this._ontouchend = function (e) {
        self.cancel()
        self._hasMoved = false
        // clearTimeout(self._timeout)

      }
      
      this._ontouchmove = function (e) {
        console.log('touch-move', e);
        self.cancel()
        if (!self._hasMoved) {
          self._hasMoved = true
        }
      }

      this.start = () => {
        console.log('press-start');
        vnode.child.$emit('start');
        self.controller.toggle = true;
        self.controller.step = self.duration / 100;
        self.controller.timestamp = Date.now();
        self.controller.timer = setTimeout(self.update, 100);
      }
      this.update = () => {
        console.log(self._hasMoved, Date.now()-self.controller.timestamp);
        if (self.controller.timer!=null&&self.controller.progress<100) {
          self.controller.progress += 100/self.controller.step;
          self.controller.timer = setTimeout(self.update, 100);
          vnode.child.$emit('update', self.controller.progress);
        }else if (self.controller.progress>=100) {
          vnode.child.$emit('press');
        }
      }
      this.cancel = () => {
        if (self.controller.toggle&&self.controller.progress<100) {
          vnode.child.$emit('cancel');
        }
        self.controller.toggle = false;
        self.controller.progress = 0;
        self.controller.timer = null;
        self.controller.timestamp = null;
      }

      // touch support
      el.addEventListener('touchstart', this._ontouchstart, false)
      el.addEventListener('touchmove', this._ontouchmove, false)
      el.addEventListener('mouseleave', this._ontouchmove, false)
      document.addEventListener('touchend', this._ontouchend, false)
      
      // mouse actions
      el.addEventListener('mousedown', this._onmousedown)
      document.addEventListener('mouseup', this._onmouseup)
    },
    unbind: function (el) {
      clearTimeout(this._timeout)
      // mouse actions
      el.removeEventListener('mousedown', this._onmousedown)
      document.removeEventListener('mouseup', this._onmouseup)
      // touch support
      el.removeEventListener('touchstart', this._ontouchstart)
      el.removeEventListener('touchmove', this._ontouchmove)
      document.removeEventListener('touchend', this._ontouchend)
    }
  })
}