// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  data: {
    id: null,
    username: null,
    password: null,
    notification: null,
    name: '',
    phone: '',
    roles: [],
    brands: [],
    updatedAt: null,
    ghost: null,
    auth: {
      token: null,
      timestamp: null,
    }
  },
  notifications: {
    data: {},
    updatedAt: null
  }
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
