// Utilities
import {
  make,
} from 'vuex-pathify'


const state = {
  views: {
    loading: {
      toggle: false,
      resources: []
    },
    brand: {
      toggle: true,
      selected: null,
    },
    main: {
      back: false,
      content: 'dashboard',
      toggle: true,
    },
    dashboard: {
      tab: 'impact',
    },
    map: {
      toggle: false,
      loading: false,
      theme: 'light',
      panned: false,
      cities: {}
    },
    help: {
      toggle: false,
      context: null,
      fullscreen: false
    },
  },
  
  redirect: null,
  
  updated: false,

  debug: false,

  toast: {
    toggle: false,
    text: '',
    timeout: 0,
    button: false,
    color: '',
  },
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
